*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

 @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

 .App {
  background-size: 100% 200%;
  min-height: 100vh;
  transition: 0.3s;
  background-image: linear-gradient(-225deg, #5271C4 0%, #B19FFF 48%, #ECA1FE 100%);
  animation: backGroundAnimate 2s infinite alternate;
}

@keyframes backGroundAnimate {
  0% {
    background-position: 0% 0%;
    background-color: #5271C4;
  }
  50% {
    background-color: #B19FFF;
  }
  100% {
    background-position: 100% 100%;
    background-color: #ECA1FE;
  }
}


.container{
  min-height: 100vh;
  max-width: 1440px;
  overflow: hidden;
  padding-inline: 20px;
 padding-top: 80px;
 padding-bottom: 40px;
}
body{
  font-family: 'Inter', sans-serif;

}