.auth-body{
  background: white;
  background-image: url('../assets/yt.jpg');
  background-size: cover;
  background-position: center;
  
}
.auth-form-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
   
}
